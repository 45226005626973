// Colors
// * lighten
// * darken
// * saturate
// * desaturate
// * compliment
// * invert
// * tranparentize
// * adjust-hue
// ===============================================

$blue        : hsla(219, 60%, 40%, 1);
$lightblue   : lighten($blue, 25%);
$green       : hsla(174, 52%, 44%, 1);
$lightgreen  : lighten($green, 25%);
$red         : hsla(0, 100%, 66%, 1);
$lightred    : lighten($red, 25%);
$yellow      : hsla(48, 89%, 50%, 1);
$lightyellow : lighten($yellow, 35%);
$white       : hsla(0, 0%, 98%, 1);
$gray        : hsla(204, 8%, 76%, 1);
$lightgray   : lighten($gray, 20%);
$black       : hsla(0, 0%, 15%, 1);
$lightblack  : lighten($black, 10%);
$orange      : hsla(29, 98%, 62%, 1);
$lightorange : lighten($orange, 25%);

// Fonts
// ===============================================

@mixin web {
  font-family: 'Titillium Web', monospaced;
}

@mixin copy {
  font-family: 'Source Sans Pro', sans-serif;
}

@mixin code {
  font-family: 'Source Code Pro', monospaced;
  font-weight: 600;
}

@mixin breakpoint($width) {
  @media (max-width: $width) { @content; }
}
