@import 'variables-mixins';
/*
 * Override default swagger ui styles
  ************************************************
 */
.endpoints {
  @include web;
  h4 {
    font-weight: 600;
  }
}

.swagger-ui-container {
  max-width: 1200px;
}

.swagger-ui textarea {
  min-height: 150px;
}

.swagger-ui-container .swagger-container .swagger-ui {
  display: flex;
  @include breakpoint(660px) {
    display: block;
  }
}
.swagger-main-content {
  flex: 5 0 0;
  padding-left: 1em;
  min-width: 0;
}
.swagger-ui .opblock-body pre {
  word-wrap: break-word;
  max-width: 700px;
}

.swagger-ui .opblock-body p.pre {
  font-size: 12px;
  margin: 0;
  padding: 10px;
  white-space: pre-wrap;
  border-radius: 4px;
  background: #41444e;
  font-family: Source Code Pro,monospace;
  font-weight: 600;
  color: #fff;
}
.swagger-ui-api-navigation {
  background-color: $lightblack;
  color: $white;
  flex: 1 0 0;
  padding: 1em;
  font-size: 1.25em;
  p {
    border-bottom: 1px solid $lightgray;
    text-align: center;
    padding-bottom: 0.5em;
    text-transform: uppercase;
  }
  a {
    display: block;
    padding: 0.5em;
    color: inherit;
    font-size: 0.85em;
    text-decoration: none;
    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
}

.hidden {
  display: none !important;
}
