@import 'variables-mixins';
/**
 *
 * 1: General Body Styles;
 * 2: Header Styles;
 * 3: Main Content Styles;
 * 4: Footer Styles;
 *
 */

/*================================================
** General Body Styles
================================================*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: $black;
  min-height: 100%;
  font-size: 14px;
  @include breakpoint(480px) {
    font-size: 0.8em;
  }
}

h1, h2, h3, h4 { font-weight: 400; }

h1 {
  font-size: 3.998em;
}
h2 {
  font-size: 2.827em;
}
h3 {
  font-size: 1.999em;
}
h4 {
  font-size: 1.414em;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}

.app-container {
  position: relative;
  padding-bottom: 3em;
  height: 100%;
}

.flex-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/*================================================
** Header Styles
================================================*/

header {
  position: relative;
  background: linear-gradient(90deg, $blue 0%, $lightblue 100%);
  color: $white;
  padding: 1em;
  .header-logo {
    font-size: 1.5em;
    font-weight: 200;
    margin-right: auto;
    & img {
      width: 1.9em;
      & + span {
        display: inline-block;
        vertical-align: bottom;
        font-weight: 200;
        font-size: 1.25em;
      }
    }
  }
}

.header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.header-buttons a {
  padding: 0.5em 1em;
  display: inline-block;
  color: inherit;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-left: 1em;
  transition: 0.3s;
  border-bottom: 2px solid transparent;
  // HIDDEN FOR PRODUCTION =======================
  display: none;
  // =============================================
  &.active {
    border-bottom: 2px solid $blue;
  }
}

.header-subheader-container {
  background-color: $lightgray;
  padding: 1em;
  h4 {
    max-width: 1200px;
    margin: 0 auto;
    color: $lightblue;
    font-weight: 400;
  }
}

/*================================================
** Main Content Styles
================================================*/

.main-content {
  flex: 1 0 0;
  padding: 0;
}

.swagger-definition-seperator {
  margin-bottom: 0;
}

.stage-swagger-container-wrapper {
  position: relative;
  padding: 1em 0;
}

.stage-swagger-container {
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  width: 28em;
  div {
    text-align: center;
  }
  .swagger-download-buttons {
    width: 45%;
    margin-left: 5em;
    display: inline-block;
    button {
      border-radius: 4px;
      border: none;
      padding: 0.5em;
      width: 45%;
      margin-left: 2.5%;
      color: $black;
      border: 2px solid $green;
      background-color: transparent;
      transition: 0.3s;
      &:focus {
        outline: 0;
      }
      &:hover {
        background-color: $green;
        color: $white;
        cursor: pointer;
      }
      img {
        width: 1.25em;
      }
    }
  }
  @include breakpoint(660px) {
    width: 100%;
  }
}

.stage-selection-form {
  select {
    background-color: transparent;
    padding: 0.5em;
    width: 100%;
  }
  label {
    display: block;
    font-weight: 400;
    margin-bottom: 1em;
  }
}

.swagger-ui-container {
  .topbar {
    display: none;
  }
}

/*================================================
** Footer Styles
================================================*/

footer {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  width: 100%;
  a {
    color: $lightblue;
    text-decoration: none;
  }
}

/*================================================
** API Status View Styles
================================================*/

.status-info-container {
  padding-top: 1em;
  width: 75%;
  margin: 0 auto;
  .status-info-request-date {
    color: $white;
    background-color: $lightblue;
    border-radius: 20px;
    padding: 5px;
    font-size: 0.8em;
    font-weight: 200;
    float: right;
  }
  .status-info-item {
    padding: 5px;
    &:nth-child(even) {
    }
    h4 {
      display: inline-block;
      margin-bottom: 0;
      margin-left: 1em;
      span {

      }
    }
  }
}

.status-info-icon {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  img {
    height: 35px;
    vertical-align: middle;
  }
}

.status-info-table {
  width: 70%;
  margin: 0 auto;
}

.status-info-table table {
  width: 100%;
  margin-top: 2em;
  tr th, tr td {
    padding: 10px;
    text-align: center;
  }
  thead tr {
    background-color: $blue;
    color: $white;
    th {
      border-right: 1px solid $gray;
      &:last-child {border: none;}
    }
  }
  tbody tr {
    border-bottom: 1px solid $gray;
    td {
      img {
        width: 25px;
      }
    }
    th {
      background-color: $lightgray;
    }
  }
}

.status-info-legend {
  span {
    display: block;
    margin-top: 1em;
    img {
      width: 25px;
    }
  }
}

.status-flex-container div {
  flex: 50%;
}

.status-info-update-message {
  border: 2px solid $lightgray;
  padding: 1em;
  overflow: scroll;
  p {
    border-bottom: 1px solid $gray;
    padding: 0.5em 0;
    .api-message-ts {
     color: $gray;
    }
    .api-message-status.investigating {
      color: $yellow;
      background-color: $lightyellow;
      padding: 2px;
      border-radius: 8px;
    }
    .api-message-status.resolved {
      color: $green;
      background-color: $lightgreen;
      padding: 2px;
      border-radius: 8px;
    }
  }
}
